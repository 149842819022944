import Layout from "../components/Layout";
import "./index.scss";

export default function Index() {
  return (
    <Layout>
      <div className="coming-soon">
        <div className="header">
          <h1>Coming soon</h1>
        </div>
        <div className="body">
          <p>The site is still being made, please come back soon.</p>
        </div>
      </div>
    </Layout>
  );
}
