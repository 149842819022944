import Head from "next/head";
import "./Layout.scss";

const Layout = (props) => (
  <div>
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <title>Fundlytics - Coming soon</title>
    </Head>
    <div className="main">{props.children}</div>
  </div>
);

export default Layout;
